import * as React from "react";
import { Icon } from "../icon";

type headerSize = "subheading" | "minor";
type iconSize = "large" | "small";
interface HeaderProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    children?: any;
    iconName?: string;
    iconSize?: iconSize;
    headerSize?: headerSize;
    className?: string;
}

export const Header: React.FC<HeaderProps> = props => {
    const classes = {
        wrapper:
            props.headerSize === "minor"
                ? `${props.className || ""} flex mb-2`
                : `${props.className || ""} flex mb-5`,
        iconClass: props.iconSize === "small" ? "w-6 mr-1" : "w-7 mr-2",
        textClass:
            props.headerSize === "minor"
                ? "text-base font-medium"
                : "text-lg text-gray-700",
    };
    return (
        <div className={classes.wrapper}>
            {props.iconName && (
                <div className={classes.iconClass}>
                    <Icon name={props.iconName} />
                </div>
            )}
            <div className={classes.textClass}>{props.children}</div>
        </div>
    );
};
