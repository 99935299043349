import * as React from "react";

interface IconProps {
    name: string;
}

export class Icon extends React.Component<IconProps, undefined> {
    iconSrc(): string {
        return `/static/images/icons/${this.props.name}.png`;
    }

    render() {
        return <img className="crm-icon-header" src={this.iconSrc()} />;
    }
}
