import { parsePhoneNumberWithError } from "libphonenumber-js/min";

type ParsedPhoneNumber = {
    display: string;
    digits: string;
};

export const parsePhone = (unparsedPhoneNumber: string): ParsedPhoneNumber => {
    if (!unparsedPhoneNumber || unparsedPhoneNumber === "") {
        return {
            display: "",
            digits: "",
        };
    }
    try {
        const parsedPhoneNumberObj = parsePhoneNumberWithError(unparsedPhoneNumber, "US");
        if (!parsedPhoneNumberObj) {
            return {
                display: unparsedPhoneNumber,
                digits: unparsedPhoneNumber,
            };
        }
        return {
            display: parsedPhoneNumberObj.formatNational(),
            digits: String(parsedPhoneNumberObj.number),
        };
    } catch (e) {
        return {
            display: unparsedPhoneNumber,
            digits: unparsedPhoneNumber,
        };
    }
};
